jQuery(document).ready(function ($) {
    setTimeout(function(){
        $('.preloader_item').addClass('hide');
    },3200);

    var themeColor = Cookies.get('theme');

    function isEmptyStr(str) {
        return (!str || 0 === str.length);
    }

    let todayTime = new Date();
    let todayHour = todayTime.getHours();

    if( themeColor ) {
        $('body').attr('data-themecolor', themeColor);
        if( themeColor == 'yellow' ) {
            $('.header__themeToggler').addClass('active');
        }
    }

    if (todayHour >= 20 || todayHour <= 8) {
        if( themeColor ) {

        } else {
            $('.header__themeToggler').addClass('active');
            $('body').attr('data-themecolor', 'yellow');
            Cookies.set('theme', 'yellow', { expires: 7 });
        }
    } else {
        if( themeColor ) {

        } else {
            $('.header__themeToggler').removeClass('active');
            $('body').attr('data-themecolor', 'white');
            Cookies.set('theme', 'white', { expires: 7 });
        }
    }

    // set true VH value for all devices
    let vh = $(window).innerHeight() * 0.01;
    $('body').css('--vh', `${vh}px`);

    $(window).resize(() => {
        let vh = $(window).innerHeight() * 0.01;
        $('body').css('--vh', `${vh}px`);
    })

    $('.header__themeToggler--btn').click(function () {
        if( !$(this).parent().hasClass('active') ) {
            $(this).parent().addClass('active');
            Cookies.set('theme', 'yellow', { expires: 7 });
            $('body').attr('data-themecolor', 'yellow');
        } else {
            $(this).parent().removeClass('active');
            Cookies.set('theme', 'white', { expires: 7 });
            $('body').attr('data-themecolor', 'white');
        }
    });

    /*--- Primar slider ---*/

    var primarySlider = $('.primaryPage__slider--instence').flickity({
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        cellAlign: 'right',
        contain: true
    });

    // Flickity instance
    var flkty = primarySlider.data('flickity');
    // elements
    var $cellButtonGroup = $('.primaryPage__slider--nav');
    var $cellButtons = $cellButtonGroup.find('.primaryPage__slider--nav_btn');

    // update selected cellButtons
    primarySlider.on('select.flickity', function () {
        $cellButtons.filter('.selected')
            .removeClass('selected');
        $cellButtons.eq(flkty.selectedIndex)
            .addClass('selected');
    });

    // select cell on button click
    $cellButtonGroup.on('click', '.primaryPage__slider--nav_btn', function () {
        var index = $(this).index();
        primarySlider.flickity('select', index);
    });
    // previous
    $('.primaryPage__slider--arrows .sliderArrow.prev').on('click', function () {
        primarySlider.flickity('previous');
    });
    // next
    $('.primaryPage__slider--arrows .sliderArrow.next').on('click', function () {
        primarySlider.flickity('next');
    });

    /*--- END ---*/

    /*--- Basic contact form ajax hadler ---*/
    $('.contactForm').submit(function (e) {
        e.preventDefault();

        const _this = $(this);
        const name = _this.find('#contact_name').val();
        const phone = _this.find('#contact_phone').val();
        const email = _this.find('#contact_email').val();
        const company = _this.find('#contact_company').val();
        const button = _this.find('.contactForm__subm');
        const referer = _this.find('#referer').val();

        const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        let error = false;
        const errors_texts = _this.data('errors');

        let err_txt = '';
        let err_elem = '';

        _this.find('.contactForm__err').remove();

        if (isEmptyStr(name)) {
            error = true;
            err_txt = errors_texts.empty_err;
            err_elem = `<p class="contactForm__err">${err_txt}</p>`;
            _this.find('#contact_name').parent().append(err_elem);
        }

        if (isEmptyStr(phone)) {
            error = true;
            err_txt = errors_texts.empty_err;
            err_elem = `<p class="contactForm__err">${err_txt}</p>`;
            _this.find('#contact_phone').parent().append(err_elem);
        }

        if (isEmptyStr(email)) {
            error = true;
            err_txt = errors_texts.empty_err;
            err_elem = `<p class="contactForm__err">${err_txt}</p>`;
            _this.find('#contact_email').parent().append(err_elem);
        }

        if (!emailRegExp.test(email)) {
            error = true;
            err_txt = errors_texts.valid_err;
            err_elem = `<p class="contactForm__err">${err_txt}</p>`;
            _this.find('#contact_email').parent().append(err_elem);
        }

        if (!error) {
            const data = new FormData();
            data.append('action', 'contactform');
            data.append('name', name);
            data.append('phone', phone);
            data.append('email', email);
            data.append('company', company);
            data.append('referer', referer);

            $.ajax({
                url: ajax_object.ajaxurl,
                data: data,
                type: 'POST',
                processData: false,
                contentType: false,
                dataType: "json",
                beforeSend: function (xhr) {
                    button.addClass('loading');
                },
                success: function (data) {
                    if (data.response == "SUCCESS") {
                        let langPref = '';
                        if( window.pathname) {
                            if(window.pathname.includes('/en/') ) {
                                langPref = '/en';
                            }

                            if( window.pathname.includes('/ru/') ) {
                                langPref = '/ru'
                            }
                        } 

                        location.assign(window.location.href + langPref +'thanks-message');
                    } else {
                        console.log(data.error);
                    }
                },
                error: function () {

                }
            })
        }

        console.log(name, phone, email, company, error, errors_texts, referer);
    })
    /*--- END ---*/

    /*--- Blog page ---*/
    var blogRandomPostsSlider = $('.blogPage__slider--instence').flickity({
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        cellAlign: 'right',
        contain: true
    });

    $('.blogPage__slider--nav .arrows').click(function (e) {
        if ($(this).hasClass('prev')) {
            blogRandomPostsSlider.flickity('previous');
        }

        if ($(this).hasClass('next')) {
            blogRandomPostsSlider.flickity('next');
        }
    })
    /*--- END ---*/

    /*--- BLog single page ---*/
    var relatedPost_slider = $('.blogPage__single--related_instence').flickity({
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        cellAlign: 'right',
        contain: true,
        adaptiveHeight: true,
        groupCells: '100%'
    });

    $('.blogPage__single--related_nav .arrows').click(function (e) {
        if ($(this).hasClass('prev')) {
            relatedPost_slider.flickity('previous');
        }

        if ($(this).hasClass('next')) {
            relatedPost_slider.flickity('next');
        }
    })
    /*--- END ---*/

    /*--- BLog load mores posts ---*/
    $('.blogPage__items--loadmore_btn').click(function (e) {
        var _this = $(this);
        var button = jQuery(this),
            data = {
                'action': 'loadmore_posts',
                'query': ajax_object.posts,
                'page': ajax_object.current_page
            };

        $.ajax({
            url: ajax_object.ajaxurl,
            data: data,
            type: 'POST',
            beforeSend: function (xhr) {
                button.addClass('loading');
            },
            success: function (data) {
                if (data) {
                    var curYpos = $('html,body').scrollTop();
                    console.log(curYpos);
                    
                    setTimeout(() => {
                        button.removeClass('loading').parent().before(data);
                    }, 1000);

                    setTimeout(() => {
                        $('html,body').scrollTop(curYpos);
                    }, 1100);

                    ajax_object.current_page++;

                    if (ajax_object.current_page == ajax_object.max_page) {
                        button.fadeOut(500);
                    }
                } else {
                    button.fadeOut(500);
                }
            }
        });
    })
    /*--- END ---*/

    if ($('.modalMenuParralax_scene').length > 0) {
        var menuModal = $('.modalMenuParralax_scene').get(0);
        var parallaxInstance_1 = new Parallax(menuModal, {
            relativeInput: true,
            clipRelativeInput: true,
            hoverOnly: true
        });
    }

    $('.header__burger').click(function () {
        $('.modalMenu').addClass('open');
        $.scrollLock(true);
    });

    $('.modalMenu__close').click(function () {
        $('.modalMenu').removeClass('open');
        $.scrollLock(false);
    });

    if( $('.pageAbout__historySlider--instance').length > 0 ) {
        var temiLineSlider = new Swiper('.pageAbout__historySlider--instance', {
            slidesPerView: 1,
            effect: 'fade',
            spaceBetween: 30,
            speed: 800,
            autoHeight: true,
            mousewheel: {
                sensitivity: '3'
            },
            fadeEffect: { crossFade: true },
            virtualTranslate: true,
            navigation: {
                nextEl: '.pageAbout__historySlider--arrows .arrow.next',
                prevEl: '.pageAbout__historySlider--arrows .arrow.prev'
              }
        });
    
        setTimeout(function () {
            temiLineSlider.update();
        }, 500);
    
        $('.pageAbout__historySlider--arrows .prev, .pageAbout__historySlider--arrows .next').click(function(){
            temiLineSlider.update();
        });
    }
    

    $('.showModalContactForm').click(function(){
        $.scrollLock(true);
        $('.modalContactForm').toggleClass('show');
        const referer = $(this).data('modalreferer');
        console.log(referer);
        $('.modalContactForm #referer').val(referer);
    });

    $('body').on('click', '.showModalContactFormServices', function(){
        $.scrollLock(false);
        $('.modalServiceContent').removeClass('show');
        
        setTimeout( function() {
            $('.modalContactForm').addClass('show');
            $.scrollLock(true);
        }, 500);

        
        const referer = $(this).data('modalreferer');
        console.log(referer);
        $('.modalContactForm #referer').val(referer);
    });



    $('.modalContactForm__close').click(function(){
        $.scrollLock(false);
        $('.modalContactForm').toggleClass('show');
    });

    $('.modalContactForm').click(function(e){
        if( $(e.target).find(".modalContactForm__inner").length ) {
            $.scrollLock(false);
            $('.modalContactForm').toggleClass('show');
        }
    });

    $('.spec-scroll-link').click(function(event){
        event.preventDefault();
      
        $('html, body').animate({
              scrollTop: $($(this).attr("href")).offset().top
        }, 800, function(){
            window.location.hash = hash;
        });
    });


    if ($(location.hash).length) {
        var hash = $(location.hash);
        console.log(hash);
      
        setTimeout(() => {
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800);
        },3200);
    }

    $("a[href*='#']:not([href='#'])").click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top
          }, 1000);
          return false;
        }
      }
     });

    $('[name="contact_phone"]').inputmask("+38 (999) 999-99-99");

    function disableSliderBtn( slider, classToToggle, arrowPrev, arrowNext) {
        var curSlider = slider.data('flickity');
        slider.on('select.flickity', function() {
            if (!curSlider.slides[curSlider.selectedIndex - 1]) {
              $(arrowPrev).addClass(classToToggle);
              $(arrowNext).removeClass(classToToggle);
            } else if (!curSlider.slides[curSlider.selectedIndex + 1]) {
            $(arrowNext).addClass(classToToggle);
            $(arrowPrev).removeClass(classToToggle);
            } else {
                $(arrowPrev).removeClass(classToToggle);
                $(arrowNext).removeClass(classToToggle);
            }
        });
    }

    if( $('.primaryPage__slider--instence').length > 0 ) {
        disableSliderBtn(primarySlider, 'disabled', '.primaryPage__slider--arrows .sliderArrow.prev','.primaryPage__slider--arrows .sliderArrow.next');
    }
    

    if( $('.blogPage__slider--instence').length > 0 ) {
        disableSliderBtn(blogRandomPostsSlider, 'disabled', '.blogPage__slider--nav .arrows.prev','.blogPage__slider--nav .arrows.next');
    }

    if( $('.blogPage__single--related_instence').length > 0 ) {
        disableSliderBtn(relatedPost_slider, 'disabled', '.blogPage__single--related_nav .arrows.prev','.blogPage__single--related_nav .arrows.next');
    }

    $('.specialContentBlock .showMoreTxt').click(function(){
        $(this).parents('.specialContentBlock__line').find('.specialContentBlock__line--more').slideDown(600);
        $(this).fadeOut(400);
    });

    $('.pageContact__toggler').click(function(){
        $('.formContainer__cont').addClass('hide'); 
        $('.formContainer').addClass('hide'); 
        $('.mapContainerToggler').addClass('show');
        $('.mapContainer').addClass('show');

    });

    $('.mapContainerToggler').click(function(){
        $('.formContainer__cont').removeClass('hide');
        $('.formContainer').removeClass('hide');
        $('.mapContainerToggler').removeClass('show');
        $('.mapContainer').removeClass('show');
    });

    /*-- portfolio section front page --*/
    
    var instenceSliders = new Array();
    var instenceSlidersIndex = 0;

    $('.primaryPage__cases--items__slider--inner').each( function() {
        instenceSlidersIndex++;

        var projectsSlidersNew =  new Swiper( this , {
            loop: true,
            updateOnWindowResize: true,
            effect: 'slide',
            slidesPerView: 6,
            spaceBetween: 45,
            centeredSlides:false,
            loopFillGroupWithBlank: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 2,
                    spaceBetween: 15
                },
                760: {
                    slidesPerView: 3,
                    spaceBetween: 25
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 35
                },
                1600: {
                    slidesPerView: 6,
                    spaceBetween: 40
                },
            },
            
            navigation: {
                nextEl: '.arrow-next',
                prevEl: '.arrow-prev',
            },
        });

        instenceSliders[instenceSlidersIndex] = projectsSlidersNew;

        if (projectsSlidersNew.originalParams.loop && projectsSlidersNew.loopedSlides < projectsSlidersNew.originalParams.slidesPerView) {
            projectsSlidersNew.params.slidesPerView = projectsSlidersNew.loopedSlides;
            projectsSlidersNew.update();
        }
    } );

    console.log( instenceSliders );
    
    /*$('.primaryPage__cases--items__slider-nav .arrow-prev').click( function() {
        $(this).parent().siblings('.primaryPage__cases--items__slider--inner').flickity('previous', true);
    } );

    $('.primaryPage__cases--items__slider-nav .arrow-next').click( function() {
        $(this).parent().siblings('.primaryPage__cases--items__slider--inner').flickity('next', true);
    } );*/
    

    $('.primaryPage__cases--navs_btn').click( function() {
        var sliderId = $(this).attr('id');

        $(this).siblings('.primaryPage__cases--navs_btn').removeClass('active');
        $(this).addClass('active');

        $('.primaryPage__cases--items__slider').removeClass('show');
        $('.primaryPage__cases--items__slider[data-slider_id="'+ sliderId +'"]').addClass('show');
        instenceSliders[$(this).index() + 1 ].update();

    } );
    /*-- END --*/

    /*-- Service modal window --*/
    $('.modalServiceContent__close').click( function(){
        $('.modalServiceContent').removeClass( 'show' );
        $('.modalServiceContent').find('.modalServiceContent__loader').removeClass('hide');
        $('.modalServiceContent').find('.modalServiceContentBlock').remove();
        $.scrollLock(false);
    } );

    $('.modalServiceContent').click(function(e){
        if( $(e.target).find(".modalServiceContent__inner").length ) {
            $.scrollLock(false);
            $('.modalServiceContent').toggleClass('show');
        }
    });

    $('.showModalService').click( function() {
        
        var _this = $(this);
        var service_index = _this.data('service_repeater_index');
        var service_id = _this.data('service_id');

        var serviceModalWindow = $('.modalServiceContent__cont');

        var data = new FormData();

        data.append('action', 'get_attached_service');
        data.append('service_id', service_id);
        data.append('service_repeater_index', service_index );

        $.ajax({
            url: ajax_object.ajaxurl,
            data: data,
            type: 'POST',
            processData: false,
            contentType: false,
            dataType: "json",
            beforeSend: function (xhr) {
                $('.modalServiceContent').addClass('show');
                $.scrollLock(true);
                serviceModalWindow.find('.modalServiceContent__loader').removeClass('hide');
                serviceModalWindow.find('.modalServiceContentBlock').remove();
            },
            success: function (response) {
                if( response.status == 'success' ) {
                    serviceModalWindow.find('.modalServiceContent__loader').addClass('hide');
                    serviceModalWindow.delay(500).find('.modalServiceContent__loader').after( response.content );
                } else {
                    console.log( response.error );
                }
            },

            error: function (jqXHR, exception) {
                var msg = '';
                if (jqXHR.status === 0) {
                    msg = 'Not connect.\n Verify Network.';
                } else if (jqXHR.status == 404) {
                    msg = 'Requested page not found. [404]';
                } else if (jqXHR.status == 500) {
                    msg = 'Internal Server Error [500].';
                } else if (exception === 'parsererror') {
                    msg = 'Requested JSON parse failed.';
                } else if (exception === 'timeout') {
                    msg = 'Time out error.';
                } else if (exception === 'abort') {
                    msg = 'Ajax request aborted.';
                } else {
                    msg = 'Uncaught Error.\n' + jqXHR.responseText;
                }
                
                console.log( msg );
            },
        });
    } )
    
    $('.showModalAddService').click( function() {
        
        var _this = $(this);
        var service_id = _this.attr('id');

        var serviceModalWindow = $('.modalServiceContent__cont');

        var data = new FormData();

        data.append('action', 'get_add_service');
        data.append('service_id', service_id);

        $.ajax({
            url: ajax_object.ajaxurl,
            data: data,
            type: 'POST',
            processData: false,
            contentType: false,
            dataType: "json",
            beforeSend: function (xhr) {
                $('.modalServiceContent').addClass('show');
                $.scrollLock(true);
                serviceModalWindow.find('.modalServiceContent__loader').removeClass('hide');
                serviceModalWindow.find('.modalServiceContentBlock').remove();
            },
            success: function (response) {
                if( response.status == 'success' ) {
                    serviceModalWindow.find('.modalServiceContent__loader').addClass('hide');
                    serviceModalWindow.delay(500).find('.modalServiceContent__loader').after( response.content );
                } else {
                    console.log( response.error );
                }
            },

            error: function (jqXHR, exception) {
                var msg = '';
                if (jqXHR.status === 0) {
                    msg = 'Not connect.\n Verify Network.';
                } else if (jqXHR.status == 404) {
                    msg = 'Requested page not found. [404]';
                } else if (jqXHR.status == 500) {
                    msg = 'Internal Server Error [500].';
                } else if (exception === 'parsererror') {
                    msg = 'Requested JSON parse failed.';
                } else if (exception === 'timeout') {
                    msg = 'Time out error.';
                } else if (exception === 'abort') {
                    msg = 'Ajax request aborted.';
                } else {
                    msg = 'Uncaught Error.\n' + jqXHR.responseText;
                }
                
                console.log( msg );
            },
        });
    } )
    /*-- END --*/

    /*-- Button shows a modalCases --*/

    $('.showModalCases').click(function(){
        $.scrollLock(true);
        $('.modalCases').toggleClass('show');
    });

    $('.modalCases__close').click(function(){
        $.scrollLock(false);
        $('.modalCases').toggleClass('show');
    });

    $('.modalCases').click(function(e){
        if( $(e.target).find(".modalCases__inner").length ) {
            $.scrollLock(false);
            $('.modalCases').toggleClass('show');
        }
    });
    /*-- END --*/

    /*-- Load more cases --*/
    $('.cases__items--loadmore_btn').click( function(e) {
        var _this, paged, data, max_paged;
        _this = $(this);
        paged = _this.prop('dataset').paged;
        max_paged = _this.data('max_page');

        data = new FormData();
        data.append('action', 'loadmore_cases');
        data.append('paged', paged);

        if ( paged == max_paged ) {
            _this.fadeOut(500);
        }

        $.ajax({
            url: ajax_object.ajaxurl,
            data: data,
            type: 'POST',
            processData: false,
            contentType: false,
            dataType: "json",

            beforeSend: function (xhr) {
                _this.addClass('loading');
            },

            success: function (response) {
                if (response.status === 'success') {
                    var curYpos = $('html,body').scrollTop();

                    setTimeout(() => {
                        _this.removeClass('loading').parent().before(response.output);
                    }, 1000);

                    setTimeout(() => {
                        $('html,body').scrollTop(curYpos);
                    }, 1000);

                    paged++;
                    _this.prop('dataset').paged = paged;

                    if ( paged == max_paged ) {
                        _this.fadeOut(500);
                    }

                } else {
                    _this.fadeOut(500);
                }
            },

            error: function (jqXHR, exception) {
                var msg = '';
                if (jqXHR.status === 0) {
                    msg = 'Not connect.\n Verify Network.';
                } else if (jqXHR.status == 404) {
                    msg = 'Requested page not found. [404]';
                } else if (jqXHR.status == 500) {
                    msg = 'Internal Server Error [500].';
                } else if (exception === 'parsererror') {
                    msg = 'Requested JSON parse failed.';
                } else if (exception === 'timeout') {
                    msg = 'Time out error.';
                } else if (exception === 'abort') {
                    msg = 'Ajax request aborted.';
                } else {
                    msg = 'Uncaught Error.\n' + jqXHR.responseText;
                }

                console.log( msg );
            },
        });

    } );
    /*-- END --*/
})